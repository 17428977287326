<template>
  <div>
    <div class="mobileNav hiddenMD">
      <div class="toggleButton">
        <a @click="showSideNav()" class="no_animation pb-0">
          <i class="fal fa-bars fa-2x" v-show="!showSide"></i>
          <i class="fal fa-times fa-2x" v-show="showSide"></i>
        </a>
      </div>
    </div>
    <transition name="slide">
      <div class="sideNav" v-if="showSide">
        <div class="sideNav__container">
          <img src="https://firebasestorage.googleapis.com/v0/b/cerda-logistics.appspot.com/o/Website%20Pics%2FUntitled%20design%20(2).png?alt=media&token=d64ecf6d-94db-4ab2-8847-45726d85d9ae" alt="" style="width:100%;">
          <div class="sideNav__menu mt-5">
<!--             <div class="sideNav__menu--item">
              <router-link :to="{name: 'dashboard'}" v-bind:class="{ 'active': isDashboard }">
                Home 
              </router-link>
            </div> -->
            <div class="sideNav__menu--item">
              <router-link :to="{name: 'fleetHome'}" v-bind:class="{ 'active': isVehicles }">
                <i class="fa-regular fa-truck-fast mr-2"></i> My Fleet
              </router-link>
            </div>
           <!--  <div class="sideNav__menu--item">
              <router-link :to="{name: 'addVehicle'}">
                Add Vehicle
              </router-link>
            </div> -->
            <div class="sideNav__menu--item">
              <router-link :to="{name: 'tickets'}" v-bind:class="{ 'active': isTickets }">
                <i class="fa-regular fa-screwdriver-wrench mr-2"></i> Work Orders
              </router-link>
            </div>
            <div class="sideNav__menu--item">
              <router-link :to="{name: 'messages'}" v-bind:class="{ 'active': isMessages }">
                <i class="fa-regular fa-inbox-full mr-2"></i> Messages
              </router-link>
            </div>
            <div class="sideNav__menu--item" v-if="userProfile && userProfile.role == 'Admin'">
              <router-link :to="{name: 'invoices'}" v-bind:class="{ 'active': isInvoices }">
                <i class="fa-regular fa-file-invoice mr-2"></i> Invoices
              </router-link>
            </div>
            <div class="sideNav__menu--item">
              <router-link :to="{name: 'account'}">
                <i class="fa-regular fa-user mr-2"></i> My Account
              </router-link>
            </div>
            <div class="sideNav__menu--item">
              <a href="#" @click="logout()">
                <i class="fa-regular fa-right-from-bracket mr-2"></i> Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import router from '@/router'

export default {
  props: ['userProfile'],
  data: () => ({
    showSide: true,
    window: {
      width: 0
    }
  }),
  computed: {
    isDashboard() {
      return this.$route.name == 'dashboard';
    },
    isMessages() {
      return this.$route.name == 'messages';
    },
    isInvoices() {
      return this.$route.name == 'invoices';
    },
    isVehicles() {
      return (this.$route.name == 'fleetHome' || this.$route.name == 'vehicles' || this.$route.name == 'vehicle' || this.$route.name == 'addVehicle');
    },
    isTickets() {
      return (this.$route.name == 'ticketsHome' || this.$route.name == 'tickets' || this.$route.name == 'ticket' || this.$route.name == 'addTicket');
    },
  },
  watch:{
    $route (to, from){
      if (this.window.width < 600) {
        this.showSide = false;
      } else {}
      
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
    showSideNav() {
      this.showSide = !this.showSide
    },
    handleResize() {
      this.window.width = window.innerWidth;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>