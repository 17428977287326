import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebaseConfig'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/', redirect: '/fleet/vehicles',
      name: 'dashboard',
      component: () => import('../views/Home.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('../views/Account.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/inbox',
      name: 'messages',
      component: () => import('../views/Messages.vue'),
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/invoices', redirect: '/invoices/all',
      name: 'invoicesHome',
      component: () => import('../views/Invoices/InvoicesHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/invoices/all',
          name: 'invoices',
          component: () => import('../views/Invoices/Invoices.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/invoices/all/:id',
          name: 'invoice',
          component: () => import('../views/Invoices/Invoice.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/fleet', redirect: '/fleet/vehicles',
      name: 'fleetHome',
      component: () => import('../views/Fleet/FleetHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/fleet/vehicles',
          name: 'vehicles',
          component: () => import('../views/Fleet/Vehicles.vue'),
          props: true,
        },
        {
          path: '/fleet/vehicles/:id',
          name: 'vehicle',
          component: () => import('../views/Fleet/Vehicle.vue'),
        },
        {
          path: '/fleet/addvehicle',
          name: 'addVehicle',
          component: () => import('../views/Fleet/AddVehicle.vue'),
        },
      ]
    },
    {
      path: '/tickets', redirect: '/workorders',
      name: 'ticketsHome',
      component: () => import('../views/Tickets/TicketsHome.vue'),
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: '/workorders',
          name: 'tickets',
          component: () => import('../views/Tickets/Tickets.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/workorders/:id',
          name: 'ticket',
          component: () => import('../views/Tickets/Ticket.vue'),
          props: true,
          meta: {
            requiresAuth: true
          },
        },
        {
          path: '/addticket',
          name: 'addTicket',
          component: () => import('../views/Tickets/AddTicket.vue'),
          meta: {
            requiresAuth: true
          },
        },
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../views/Auth/Login.vue')
    },
  ]
})

router.beforeEach((to, from, next) => {
  let currentUser = auth.currentUser;
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } 
  else if (!requiresAuth && currentUser) next();
  else 
  {
    next()
  }
})

export default router