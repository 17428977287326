import Vue from 'vue'
import Vuex from 'vuex'
const fb = require('../firebaseConfig.js')
import router from '../router/index'
import firebase from 'firebase/app';
const db = getFirestore();
import { getFirestore, getDocs, getDoc, doc, onSnapshot, collection, query, where, setDoc, addDoc, Timestamp, updateDoc, serverTimestamp, deleteDoc, orderBy, limit } from "firebase/firestore";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";

Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile', user)
  }
})



const store = new Vuex.Store({
  state: {
    userProfile: {},
    currentUser: null,
    vehicles: [],
    vehicleInfo: {},
    tickets: [],
    vehicleTickets: [],
    vehicleRecommendations: [],
    ticketInfo: {},
    ticketsTotal: [],
    ticketsTotalNext: [],
    ticketMessages: [],
    ticketInvoices: [],
    invoices: [],
    invoiceInfo: {},
    accountInfo: {},
    messages: []
  },
  actions: {
    async login({ dispatch, commit }, form) {
      // sign user in

      const auth = getAuth();
      signInWithEmailAndPassword(auth, form.email, form.password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
          store.dispatch('fetchUserProfile', user)
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
        });
    },
    async fetchUserProfile({ commit }, user) {
      console.log(user.uid)
      try {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if(docSnap.exists()) {
            console.log(docSnap.data());
            commit('setUserProfile', docSnap.data())
            store.dispatch('getVehicles')
            store.dispatch('getTickets')
            store.dispatch('getInvoices')
            store.dispatch('getMessages')
            store.dispatch('getAccountFromId', docSnap.data().dps.id)
        } else {
            console.log("Document does not exist")
            const auth = getAuth();

            await auth.signOut()
        }

      } catch(error) {
          console.log(error)
      }
    },
    async logout({ commit }) {
     console.log("logging out")
      const auth = getAuth();

      await auth.signOut()
      // clear user data from state
      commit('setUserProfile', {})
      commit('setCurrentUser', null)
      commit('setInvoices', null)
      commit('setVehicles', null)
      commit('setTickets', null)
      // redirect to login view
      router.push('/login')
    },


    /*ACCOUNT*/
    async getAccountFromId({ commit, state }) {
      const docRef = doc(db, "dps", state.userProfile.dps.id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("document!");
        commit("setAccountInfo", docSnap.data())
        } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },

    /*INVOICES*/

    async getTicketInvoices({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "invoices"), where("ticket.id", "==", payload), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let invoicesArray = []
        querySnapshot.forEach((doc) => {
          let invoice = doc.data()
          invoicesArray.push(invoice)
        });
        commit('setTicketInvoices', invoicesArray)
      });
    },
    async getInvoices({ commit, state }) {
      const q = query(collection(db, "invoices"), where("dps", "==", state.userProfile.dps.id), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let invoicesArray = []
        querySnapshot.forEach((doc) => {
          let invoice = doc.data()
          invoicesArray.push(invoice)
        });
        commit('setInvoices', invoicesArray)
      });
    },
    async getInvoiceFromId({ commit }, payload) {
      const docRef = await doc(db, "invoices", payload);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        commit("setInvoiceInfo", docSnap.data())
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    clearInvoiceInfo({ commit }) {
      commit("setInvoiceInfo", {})
    },


    /*MESSAGES*/
    async sendTicketMessage({ commit }, payload) {
      console.log(payload)
      const docRef = addDoc(collection(db, "ticketMessages"), payload)
     .then(
        doc => {
          store.dispatch('addTicketMessageId', doc.id)
          // store.dispatch('getTicketMessages', payload.ticket)
        }
      )
    },
    async addTicketMessageId({ commit }, payload) {
      console.log(payload)
      const messageRef = await doc(db, "ticketMessages", payload)

      updateDoc(messageRef, {
        status: 'New',
        id: payload,
        created: serverTimestamp()
      })
    },
    async getTicketMessages({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "ticketMessages"), where("ticket", "==", payload), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let messagesArray = []
        querySnapshot.forEach((doc) => {
          let message = doc.data()
          messagesArray.push(message)
        });
        commit('setTicketMessages', messagesArray)
      });
    },
    async getMessages({ commit, state }) {
      const q = query(collection(db, "ticketMessages"), where("dpsId", "==", state.userProfile.dps.id), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let messagesArray = []
        querySnapshot.forEach((doc) => {
          let message = doc.data()
          messagesArray.push(message)
        });
        commit('setMessages', messagesArray)
      });
    },
    clearMessages({ commit }) {
      commit('setMessages', [])
    },

    /*TICKETS*/

    async getTicketsTotal({ commit }) {
      const querySnapshot = await getDocs(collection(db, "tickets"))     
      console.log(querySnapshot.size);
      commit('setTicketsTotal', querySnapshot.size)
      commit('setTicketsTotalNext', (querySnapshot.size + 2400))
    },
    async getTickets({ commit, state }) {
      const q = query(collection(db, "tickets"), where("ticket.dps.id", "==", state.userProfile.dps.id), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let ticketsArray = []
        querySnapshot.forEach((doc) => {
          console.log(doc.data())
          let ticket = doc.data()
          if (!ticket.deleted) {
            ticketsArray.push(ticket)
        } else {}
        });
        console.log(ticketsArray)
        commit('setTickets', ticketsArray)
      });
    },

    addTicket({ commit }, payload) {
     const docRef = addDoc(collection(db, "tickets"), payload)
     .then(
        doc => {
          store.dispatch('addTicketId', doc.id)
        }
      )
    },
    async addTicketId({ commit }, payload) {
      console.log(payload)
      const ticketRef = await doc(db, "tickets", payload)
      updateDoc(ticketRef, {
        status: 'Open',
        id: payload,
        created: serverTimestamp()
      });
      store.dispatch('getTickets')
    },
    async getTicketFromId({ commit }, payload) {
      console.log(payload)
      const docRef = doc(db, "tickets", payload);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        commit("setTicketInfo", docSnap.data())
        store.dispatch('getTicketMessages', docSnap.id)
        store.dispatch('getTicketInvoices', docSnap.id)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async updateTicket({ commit }, payload) {
      console.log(payload)
      const ticketRef = doc(db, "tickets", payload.id)
      await updateDoc(ticketRef, payload);
      store.dispatch('getTickets')
    },
    async deleteTicket({ commit }, payload) {
      await deleteDoc(doc(db, "tickets", payload.id));
      store.dispatch('getTickets')
    },
   
    
    clearTicket({ commit }) {
      commit("setTicketInfo", {})
      commit('setTicketMessages',[])
      commit('setTicketInvoices',[])
    },
    clearTickets({ commit }) {
      commit('setTickets', [])
    },

    /*RECOMMENDATIONS*/


    /*VEHICLES*/
    // async getVehicles({ commit }) {
    //   const querySnapshot = await getDocs(collection(db, "vehicles"));
    //   let vehiclesArray = []
    //   querySnapshot.forEach((doc) => {
    //     let vehicle = doc.data()
    //     if (!vehicle.deleted) {
    //       vehiclesArray.push(vehicle)
    //     } else {}
    //   });
    //   commit('setVehicles', vehiclesArray)
    // },
    async getVehicles({ commit, state }) {
      console.log(state.userProfile.dps)
      const q = query(collection(db, "vehicles"), where("dps", "==", state.userProfile.dps.id), orderBy("created", "desc"));
      const uu = onSnapshot(q, (querySnapshot) => {
        let vehiclesArray = []
        querySnapshot.forEach((doc) => {
          let vehicle = doc.data()
          if (!vehicle.deleted) {
            vehiclesArray.push(vehicle)
        } else {}
        });
        commit('setVehicles', vehiclesArray)
      });
    },


    async addVehicle({ commit }, payload) {
     const docRef = await addDoc(collection(db, "vehicles"), payload)
     .then(
        doc => {
          store.dispatch('addVehicleId', doc.id)
        }
      )
     store.dispatch('getVehicles')
    },
    addVehicleId({ commit }, payload) {
      console.log(payload)
      const vehicleRef = doc(db, "vehicles", payload)
      updateDoc(vehicleRef, {
        id: payload,
        created: serverTimestamp(),
      });
      store.dispatch('getVehicles')
    },
    async updateVehicle({ commit }, payload) {
      console.log(payload)
      const vehicleRef = doc(db, "vehicles", payload.id)
      await updateDoc(vehicleRef, payload);
      store.dispatch('getVehicles')
    },
    async deleteVehicle({ commit }, payload) {
      await deleteDoc(doc(db, "vehicles", payload.id));
      store.dispatch('getVehicles')
    },
    async getVehicleFromId({ commit }, payload) {
      console.log(payload)
      
      const docRef = doc(db, "vehicles", payload);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        commit("setVehicleInfo", docSnap.data())
        store.dispatch('getVehicleTickets', docSnap.id)
        store.dispatch('getVehicleRecommendations', docSnap.id)
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    },
    async getVehicleTickets({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "tickets"), where("vehicleId", "==", payload));
      const querySnapshot = await getDocs(q)
      // const querySnapshot = await getDocs(collection(db, "tickets"), where("vehicleId", "===", payload));
      let ticketsArray = []
      querySnapshot.forEach((doc) => {
        
        let ticket = doc.data()
        
        ticketsArray.push(ticket)
        // if (!ticket.deleted) {
        //   ticketsArray.push(ticket)
        // } else {}
      });
      commit('setVehicleTickets', ticketsArray)
    },
    async getVehicleRecommendations({ commit }, payload) {
      console.log(payload)
      const q = query(collection(db, "recommendations"), where("vehicleId", "==", payload));
      const querySnapshot = await getDocs(q)
      // const querySnapshot = await getDocs(collection(db, "tickets"), where("vehicleId", "===", payload));
      let recommendationsArray = []
      querySnapshot.forEach((doc) => {
        
        let recommendation = doc.data()
        
        recommendationsArray.push(recommendation)
        // if (!recommendation.deleted) {
        //   recommendationsArray.push(recommendation)
        // } else {}
      });
      commit('setVehicleRecommendations', recommendationsArray)
    },
    clearVehicle({ commit }) {
      commit("setVehicleInfo", {})
      commit('setVehicleTickets', [])
      commit('setVehicleRecommendations', [])
    },
    clearVehicles({ commit }) {
      commit('setVehicles', [])
    },
  },
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setVehicleInfo(state, val) {
      state.vehicleInfo = val
    },
    setVehicles(state, val) {
      if (val) {
        state.vehicles = val
      } else {
        state.vehicles = []
      }
    },
    setTicketsTotal(state, val) {
      state.ticketsTotal = val
    },
    setTicketsTotalNext(state, val) {
      state.ticketsTotalNext = val
    },
    setTicketInfo(state, val) {
      state.ticketInfo = val
    },
    setTickets(state, val) {
      if (val) {
        state.tickets = val
      } else {
        state.tickets = []
      }
    },
    setMessages(state, val) {
      if (val) {
        state.messages = val
      } else {
        state.messages = []
      }
    },
    setVehicleTickets(state, val) {
      if (val) {
        state.vehicleTickets = val
      } else {
        state.vehicleTickets = []
      }
    },
    setVehicleRecommendations(state, val) {
      if (val) {
        state.vehicleRecommendations = val
      } else {
        state.vehicleRecommendations = []
      }
    },
    setTicketMessages(state, val) {
      if (val) {
        state.ticketMessages = val
      } else {
        state.ticketMessages = []
      }
    },
    setTicketInvoices(state, val) {
      if (val) {
        state.ticketInvoices = val
      } else {
        state.ticketInvoices = []
      }
    },
    setInvoices(state, val) {
      if (val) {
        state.invoices = val
      } else {
        state.invoices = []
      }
    },
    setInvoiceInfo(state, val) {
      state.invoiceInfo = val
    },
    setAccountInfo(state, val) {
      state.accountInfo = val
    },
  },
})

export default store
