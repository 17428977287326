import * as firebase from "firebase/app";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";

const firebaseConfig = {
  apiKey: "AIzaSyBiBmiYemW_Xmng3ZvQb_79TE1_1DxhIhM",
  authDomain: "cerda-logistics.firebaseapp.com",
  projectId: "cerda-logistics",
  storageBucket: "cerda-logistics.appspot.com",
  messagingSenderId: "1091119093485",
  appId: "1:1091119093485:web:6b5777511b36d26af25ee4",
  measurementId: "G-ZE3PQTZ89W"
}
firebase.initializeApp(firebaseConfig);

const db = getFirestore();
// db.enablePersistence();
// const firestore = firebase.firestore
const auth = getAuth()
const storageRef = getStorage()
const currentUser = auth.currentUser
const functions = getFunctions()


export {
  db,
  auth,
  storageRef,
  doc,
  getDocs,
  currentUser,
  functions,
}